<template>
  <div>
    <b-card-actions
      action-collapse
      :show-loading="busy"
      :title="$t('Atendimento')"
    >
      <FormulateForm
        ref="form"
        @submit="saveServiceRequest"
      >
        <b-row>
          <b-col md="6">
            <FormulateInput
              id="service-area"
              v-model="serviceRequest.serviceAreaId"
              :label="$t('Area')"
              type="vue-select"
              :options="serviceAreaOptions"
              validation="required"
              class="required"
              @input="localFetchServiceCategory()"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              id="service-category"
              v-model="serviceRequest.serviceCategoryId"
              :label="$t('Categoria')"
              type="vue-select"
              :disabled="!serviceRequest.serviceAreaId"
              :options="serviceCategoryOptions"
              validation="required"
              class="required"
              @input="serviceCategorySelected()"
            />
          </b-col>
          <b-col md="12">
            <e-user-search
              v-if="isRequestedForUser"
              id="user_id"
              v-model="serviceRequest.userId"
              :label="$t('Para quem?')"
              :required="isRequestedForUser"
              name="user_id"
            />
            <e-store-combo
              v-else
              v-model="serviceRequest.storeId"
              :required="!isRequestedForUser"
              only-active-options
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="service-description"
              v-model="serviceRequest.description"
              :label="$t('Descrição')"
              type="textarea"
              rows="20"
              validation="required"
              class="required"
              style="font-size: 1.5rem;"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </b-card-actions>

    <b-row>
      <b-col md="12">
        <b-card-actions
          :show-loading="busy"
          action-collapse
          :title="$t('Anexos')"
        >
          <files-form
            :files="files"
            :is-read-only="false"
            @set-files="setFiles"
            @remove-file="removeFile"
          />
        </b-card-actions>
      </b-col>
    </b-row>

    <e-crud-actions
      :busy="busy"
      @save="saveServiceRequest"
      @cancel="$router.back()"
    />
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { alerts, uploader } from '@/mixins'
import { EStoreCombo, EUserSearch } from '@/views/components'
import ECrudActions from '@/views/components/ECrudActions.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import FilesForm from '@/views/components/forms/FilesForm.vue'

export default {
  components: {
    BCol,
    BRow,
    ECrudActions,
    BCardActions,
    EUserSearch,
    EStoreCombo,
    FilesForm,
  },

  mixins: [alerts, uploader],

  data() {
    return {
      busy: false,
      status: null,
      serviceAreaId: null,
      serviceCategoryId: null,
      description: null,
      storeId: null,
      userId: null,
      createdDate: null,
    }
  },

  computed: {
    ...mapState('pages/requests/serviceRequestCreate', {
      serviceRequest: 'serviceRequest',
      stFiles: 'files',
    }),
    ...mapGetters('pages/requests/serviceRequestCreate', [
      'serviceAreaOptions',
      'serviceCategoryOptions',
    ]),

    selectedServiceCategory() {
      // eslint-disable-next-line eqeqeq
      return this.serviceCategoryOptions?.find(sc => sc.id == this.serviceRequest.serviceCategoryId)
    },

    isRequestedForUser() {
      return this.selectedServiceCategory?.requestedFor === 'Person'
    },
    files: {
      get() {
        return this.stFiles
      },
      set(val) {
        this.setFiles(val)
      },
    },
  },

  watch: {
  },

  created() {},

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/requests/serviceRequestCreate', [
      'fetchAllServiceArea',
      'fetchServiceCategory',
      'setFiles',
      'deleteLocalFile',
      'uploadDocuments',
      'cleanState',
    ]),

    async removeFile(file) {
      this.deleteLocalFile(file)
    },

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchAllServiceArea()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    async saveServiceRequest() {
      this.$refs.form.showErrors()
      if (this.$refs.form.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.busy = true
        const data = await this.$http({
          url: '/api/service/requests',
          method: 'POST',
          data: this.serviceRequest,
        })
        await this.actionsAfterSave(data?.data)
      } catch (error) {
        console.error(error)
        this.showError({ messages: error.message })
      } finally {
        this.busy = false
      }
    },

    async actionsAfterSave(data) {
      this.showSuccess({ message: this.$t('Salvo com sucesso.') })

      let hasFileError = false
      try {
        await this.uploadDocuments(data?.id)
      } catch (uploadError) {
        this.showError({
          title: this.$t('Ocorreu um erro ao fazer upload dos anexos'),
          error: uploadError,
        })
        hasFileError = true
      }

      if (!hasFileError && (this.stFiles.some(f => !f.id))) {
        this.showSuccess({ message: this.$t('Anexos salvos com sucesso.') })
      }

      this.cleanState()
      this.$router.push({ name: 'service-request-list' })
    },

    async localFetchServiceCategory() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          this.serviceRequest.serviceCategoryId = null
          await this.fetchServiceCategory(this.serviceRequest.serviceAreaId)
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    serviceCategorySelected() {
      this.serviceRequest.description = this.selectedServiceCategory?.descriptionTemplate
    }
  },
}
</script>
